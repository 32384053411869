@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .heading-big {
    font-family: "PT Serif", serif;
  }

  .btn-transparent {
    @apply bg-transparent border border-solid border-primary rounded-[8px] px-7 py-2 text-base font-[400] text-primary inline-block hover:bg-primary hover:text-white duration-300;
  }

  .btn-primary {
    @apply bg-primary rounded-[8px] px-7 py-2 text-base font-[400] text-white inline-block hover:bg-opacity-75;
  }

  .btn-secondary {
    @apply bg-secondary rounded-[8px] px-7 py-2 text-base font-[400] text-white inline-block hover:bg-opacity-75;
  }

  .heading-wrapper {
    @apply mb-3
  }

  .heading {
    @apply text-secondary text-xl font-medium text-opacity-75;
  }

  .heading-line {
    @apply relative pb-3 font-medium mb-5 inline-block after:absolute before:absolute before:top-full after:top-full after:content-[''] before:content-[''] before:inset-x-0 before:bg-white before:h-[4px] before:rounded-lg after:right-[20%] after:bg-primary after:h-[4px] after:w-5 before:w-[90%]
  }

  .text-balance {
    text-wrap: balance;
  }

  .partners .swiper-button-prev::after,
  .partners .swiper-button-next::after {
    @apply text-primary;
    font-size: 30px;
  }

  .audio-wrapper ul::-webkit-scrollbar {
    width: 5px;
  }

  .audio-wrapper ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
  }

  .audio-wrapper ul::-webkit-scrollbar-thumb {
    @apply bg-primary;
    border-radius: 10px;
  }

  .audio-wrapper ul::-webkit-scrollbar-thumb:hover {
    @apply bg-primary bg-opacity-80;
  }

  .autoplay-progress {
    @apply text-white;
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .autoplay-progress svg {
    @apply stroke-white;
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    fill: none;
    stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
  }

  .hero-section .swiper-button-prev,
  .hero-section .swiper-button-next {
    @apply text-primary;
    font-size: 14px;
  }

  .hero-section .swiper-button-prev {
    left: 60px;
  }

  .hero-section .swiper-button-next {
    right: 60px;
  }

  .blood-tabs .react-tabs__tab {
    outline: none;
    cursor: pointer;
  }

  .blood-tabs .react-tabs__tab h6 {
    @apply bg-transparent items-center border border-solid border-primary text-primary md:text-[30px] text-xl px-[24px] md:py-[12px] py-[8px] md:w-[91px] w-[77px];

    font-weight: 600;
    border-radius: 10px;
    transition: 0.5s width ease-in-out;
  }

  .blood-tabs .react-tabs__tab svg {
    @apply w-0 h-0 opacity-0 duration-500 transition-all ease-linear;

  }

  .blood-tabs .react-tabs__tab--selected h6 {
    @apply flex gap-2 bg-primary text-white md:w-[128px] w-[117px];

    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }

  .blood-tabs .react-tabs__tab--selected svg {
    @apply w-auto h-auto opacity-100 flex-none;

    cursor: pointer;
  }
  .about-vision-goals .vision-ul li {
    @apply relative pl-6 [&:not(:last-child)]:mb-4;

  }

  .about-vision-goals .vision-ul li::before {
    @apply absolute top-[4px] left-0;

    content: '';
    width: 15px;
    height: 15px;
    background: url('/public/drop.svg') no-repeat center;
    background-size: contain;
    filter: brightness(7.5);
  }

  .resarch-page .research-contents li::before {
    @apply !top-[9px];
  }

  .footer .office-li li {
    @apply relative pl-6 [&:not(:last-child)]:mb-4;

  }

  .footer .office-li li::before {
    @apply absolute top-[4px] left-0;
    content: '';
    width: 15px;
    height: 15px;
    background: url('/public/clock_icon.svg') no-repeat center;
    background-size: contain;
  }

  .details-content li {
    @apply relative pl-6 [&:not(:last-child)]:mb-4;

  }

  .details-content li::before {
    @apply absolute top-[6px] left-0;
    content: '';
    width: 15px;
    height: 15px;
    background: url('/public/drop.svg') no-repeat center;
    background-size: contain;
  }

  .section-padding {
    padding-left: 50px;
    padding-right: 50px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

/* HTML: <div class="loader"></div> */
.submitloader {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
  margin: 8px 20px;
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 #fff, -20px 0 #adadad;
    background: #fff
  }

  33% {
    box-shadow: 20px 0 #fff, -20px 0 #adadad;
    background: #adadad
  }

  66% {
    box-shadow: 20px 0 #adadad, -20px 0 #fff;
    background: #adadad
  }

  100% {
    box-shadow: 20px 0 #adadad, -20px 0 #fff;
    background: #fff
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.news-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  text-align: start;
}

.news-content p {
  color: #777777;
  font-size: 16px;
}

.news-content h1 {
  font-size: 25px;
  font-weight: bold;
}

.news-content h2 {
  font-weight: bolder;
  font-size: 22px;
}

.news-content h3 {
  font-weight: bolder;
  font-size: 19px;
}

.news-content img {
  display: block;
  width: 100%;
  object-fit: contain;
}

.about-us h1 {
  font-weight: bolder;
  font-size: 25px;
}

.about-us h2 {
  font-weight: bolder;
  font-size: 22px;
}

.about-us h3 {
  font-weight: bolder;
  font-size: 19px;
}

.about-us h4 {
  font-weight: bolder;
  font-size: 16px;
}

.about-us p {
  text-align: start;
}

/* .banner-wrapper {
  z-index: 0;
  &::before{
    position: absolute;
    background: url(../public/bg.svg) no-repeat right;
    content: '';
    inset: 0;
    z-index: -1;
  }
} */

.react-datepicker-wrapper {
  width: 100%;
}

/* @media only screen and (min-width: 1600px) {
  .section-padding {
    padding-left: 0;
    padding-right: 0;
  }
} */

@media only screen and (max-width: 1600px) {
  .hero-section .swiper-button-next {
    right: 0;
  }

  .hero-section .swiper-button-prev {
    left: 0;
  }


}

@media only screen and (max-width: 768px) {
  .section-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
}